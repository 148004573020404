/* Main container */
.MobileNavigation {
    width:88%;
    height:92px;
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left:6%;
    padding-right:6%;
    position:sticky;
    background-color: white;
    top:0px;
}

/* Box shadow when scrolled */
.MobileNavigation.shadow {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Burger icon */
.burger-icon {
    width: 24px;
    height: 18px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
}

.line {
    width: 100%;
    height: 2.4px;
    background-color: #333;
    transition: transform 0.3s ease, opacity 0.3s ease;
    z-index: 1001;
}

.line.open:nth-child(1) {
    transform: translateY(7.9px) rotate(45deg);
}

.line.open:nth-child(2) {
    opacity: 0;
}

.line.open:nth-child(3) {
    transform: translateY(-7.9px) rotate(-45deg);
}

.open {
    background-color:#333;
}


.Mobile-App-header {
    display:flex;
    justify-content: center;
}

.logo {
    height: 26px;
}

.micro-logo {
    display:none
}

@media screen and (max-width: 500px) {
    .logo {
        display:none
    }

    .micro-logo {
        display:block;
        height:46px;
    }
}



.whole-right-nav {
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap:30px
}

.getstarted-button {
    background-color:#FF7844;
    color:#F5F5F5;
    padding:8px;
    padding-right:14px;
    padding-left:14px;
    border-width:0px;
    border-radius:8px;
    font-family: 'Inter', sans-serif;
    font-size:16px;
    font-weight:500;
    cursor:pointer;
    text-decoration: none;
    display:flex;
    justify-content: center;
    align-items: center;
}

.getstarted-button:hover {
    background-color:#ffad7b;
    opacity: 0.6;
    transition: 0.3s;

}

.login-button {
    background-color:transparent;
    color:#202020;
    padding-right:12px;
    padding-left:12px;
    padding:8px;
    border-width:0px;
    font-family: 'Inter', sans-serif;
    font-size:16px;
    font-weight:500;
    cursor:pointer;
    width:61.62px;
    text-decoration: none;

}

.login-button:hover {
    color:#FF7844;
}

.nav-buttons {
    display:flex;
    flex-direction: row;
}

/* Mobile nav styling */
.mobile-nav-menu {
    position: fixed;
    top: 85px; /* Initial position */
    right: 6%;
    width: 140px;
    background-color: white;
    display: none;
    flex-direction: column;
    align-items: center;
    z-index: 1000;
    padding: 32px;
    border-radius: 8px;
    border: 1px solid #64646438;
    opacity: 0; /* Start as invisible */
    transform: translateY(5px); /* Start 5px lower */
    transition: transform 0.3s ease, opacity 0.3s ease; /* Smooth transition */
}

.mobile-nav-menu.open {
    opacity: 1; /* Fade in */
    transform: translateY(0); /* Move up 5px */
    display:flex;
}

.mobile-nav-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: center;
    display:flex;
    gap:32px;
    flex-direction: column;
}

.mobile-nav-menu li {
    margin: 0px 0px;
}

.mobile-nav-menu a {
    color: #646464;
    font-size: 16px;
    text-decoration: none;
}

.mobile-nav-menu a:hover {
    text-decoration: underline;
}