@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

.PricingCard {
    width: calc((100vw - 120px - 120px - 6px - 32px) / 3);
    height: 440px;
    padding: 20px;
    border: 1px solid rgba(198, 196, 196, 0.61);
    border-radius: 12px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 240px;
}

@media (max-width: 998px) {
    /* Your CSS styles go here */
    .PricingCard {
        width:100%;
        min-width:200px;
    }
  }

.PricingCard h2 {
    font-size: 24px;
    color: #202020;
    font-weight: 500;
    margin: 0;
    margin-bottom: 6px;
}

.PricingCard p {
    font-size: 16px;
    font-weight: 400;
    color: #646464;
    margin: 0;
    margin-bottom: auto; /* This will push the content below to the bottom */
}

.PricingCard button {
    width: 100%;
    height: 44px;
    border-radius: 8px;
    background-color: #236a28d4;
    border: 0;
    color: white;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    margin-top: 20px;
    font-family: 'Inter', sans-serif;
}

.PricingCard h4 {
    font-size: 18px;
    font-weight: 500;
    color: #202020;
    margin-top: 20px;
}

.money-pricing-card {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    margin-bottom: 20px;
}

.money-pricing-card h3 {
    font-size: 32px;
    margin: 0;
}

.money-pricing-card p {
    font-size: 14px;
    font-weight: 300;
    margin: 0;
}

.feature-pricing-card {
    display:flex;
    flex-direction:row;
    gap:10px;
}

.features-container {
    display: flex;
    flex-direction: column;
    gap: 6px;
}
