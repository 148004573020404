@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

.Pricing {
    padding-left:60px;
    padding-right:60px;
    display:flex;
    flex-direction: column;
    align-items: center;
    background-color: #f9f9f9;
}

@media (max-width: 768px) {
    /* Your CSS styles go here */
    .Pricing {
        padding-left:20px;
        padding-right:20px;
        text-align:center
    }
  }

.Pricing-header {
    margin-top:80px;
    margin-bottom:60px;
    padding-left: 40px;
    padding-right:40px;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media (max-width: 768px) {
    /* Your CSS styles go here */
    .Pricing-header {
        padding-left:0px;
        padding-right:0px;
        text-align:center
    }
  }

.Pricing-header h1 {
    color:#13164b;
    margin:0px;
}

.Pricing-header p {
    color:#646464;
    margin:0px;
    margin-top:10px;
}

.pricing-toggle {
    width:300px;
    height:40px;
    border: 1px solid rgba(198, 196, 196, 0.61);
    border-radius: 7px;
    margin-bottom:40px;
}

@media (max-width: 400px) {
    /* Your CSS styles go here */
    .pricing-toggle {
        width:240px;
    }
  }

.year-button {
    height:100%;
    width:70%;
    border-width:0px;
    padding:0px;
    cursor:pointer;
    font-size:15px;
    background-color: transparent;
    font-family: 'Inter', sans-serif;
    font-weight:300;
    border-radius: 7px;
}

.month-button {
    height:100%;
    width:30%;
    border-width:0px;
    padding:0px;
    cursor:pointer;
    font-size:15px;
    background-color: transparent;
    font-family: 'Inter', sans-serif;
    font-weight:300;
    border-radius: 7px;
}

.active {
    color: #FF7844;
    border:1px solid #FF7844;
    background-color: #ff794412;
    font-weight:400;
}

.pricing-card-container {
    display:flex;
    flex-direction: row;
    gap:16px;
    margin-bottom:40px;
    justify-content: space-between;
    width:100%;
    flex-wrap:wrap;
}