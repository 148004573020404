@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

.App-header {
    display: flex;
    justify-content: center;
}

.nav-link-container {
    display: flex;
    flex-direction: row;
    text-decoration: none;
    list-style-type: none;
    gap: 40px;
}

.nav-link {
    position: relative; /* Allows modal to be positioned relative to each nav link */
}

.nav-link-link {
    text-decoration: none;
    color: #202020;
    font-weight: 400;
    font-size: 16px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}

.nav-link-link:hover {
    color: #333333;
}

.symbol {
    font-size: 14px; /* Adjust the size */
    color: #646464; /* Change color if needed */
    vertical-align: middle; /* Align it with text */
}

.logo {
    height: 28px;
}

.Navigation {
    width: 84%;
    height: 92px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 8%;
    padding-right: 8%;
    position: sticky;
    background-color: white;
    top: 0px;
    z-index: 1000;
}

/* Box shadow when scrolled */
.Navigation.shadow {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 1236px) {
    .Navigation {
        padding-right: 20px;
        padding-left: 20px;
        width: calc(100% - 40px);
    } 
}

.getstarted-button {
    background-color: #FF7844;
    color: #F5F5F5;
    padding: 8px 14px;
    border-width: 0px;
    border-radius: 8px;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.getstarted-button:hover {
    background-color: #f67829;
    opacity: 0.6;
    transition: 0.3s;
}

.login-button {
    background-color: transparent;
    color: #202020;
    padding: 8px 12px;
    border-width: 0px;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    width: 61.62px;
    text-decoration: none;
}

.login-button:hover {
    color: #FF7844;
    opacity: 0.6;
    transition: 0.3s;
}

.nav-buttons {
    display: flex;
    flex-direction: row;
}

/* Product modal */
.modal {
    position: absolute; /* Changed to absolute positioning */
    width: 620px;
    background-color: white;
    display: flex;
    flex-direction: row;
    gap:20px;
    align-items: flex-start;
    z-index: 1000;
    padding: 26px;
    border-radius: 8px;
    border: 1px solid #64646438;
    top: 100%; /* Positioning directly below the product link */
    left: -250%; /* Aligns with the left edge of the product link */
    opacity: 0;
    transform: translateY(5px);
    transition: transform 0.3s ease, opacity 0.3s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.modal.openweb {
    opacity: 1;
    transform: translateY(0);
}

.feature-section {
    margin-bottom: 16px;
    display:flex;
    flex-direction:column;
    width:calc(50% - 10px);
    cursor:pointer;
}

.feature-section ul {
    padding:0px;
    height:100%;
    display:flex;
    flex-direction:column;
    justify-content: space-around;
}

/* Section title */
.feature-section-title {
    font-weight: 400;
    font-size: 13px;
    color: #646464;
    margin-bottom: 8px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 4px;
    margin-top:0px;
    font-family: 'Inter', 'sans-serif';
    padding-left:14px;
}

/* Feature links styling */
.feature-link {
    display: block;
    padding: 8px 0;
    color: #202020;
    text-decoration: none;
    font-size: 16px;
}

.feature-link-list-item {
    list-style-type: none;
    border-radius: 8px;
    padding:14px;
}

.feature-link-list-item:hover {
    background-color: #64646411;
}

.feature-info-header {
    display:flex;
    flex-direction: row;
    align-items: center;
    gap:8px;
    margin-bottom:8px;
}

.feature-info-header h4 {
    color:#202020;
    font-weight:500;
    margin:0px;
}

.feature-link p {
    margin:0px;
    color:#646464;
    font-weight:300;
    font-size:14px;
    padding-left:25px;
}
