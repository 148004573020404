@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

.App-header-Landing {
    display: flex;
    justify-content: center;
}

.logo-landing {
    height: 28px;
}

.LandingNavigation {
    width: 84%;
    height: 92px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 8%;
    padding-right: 8%;
    position: sticky;
    background-color: white;
    top: 0px;
    z-index: 1000;
}

/* Box shadow when scrolled */
.LandingNavigation.shadow {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 1236px) {
    .LandingNavigation {
        padding-right: 20px;
        padding-left: 20px;
        width: calc(100% - 40px);
    } 
}
