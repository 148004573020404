/* Main container */
.MobileLandingNavigation {
    width:88%;
    height:92px;
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left:6%;
    padding-right:6%;
    position:sticky;
    background-color: white;
    top:0px;
}

/* Box shadow when scrolled */
.MobileLandingNavigation.shadow {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}


.Mobile-App-header-Landing {
    display:flex;
    justify-content: center;
}

.logo-landing {
    height: 26px;
}

.micro-logo-landing {
    display:none
}

@media screen and (max-width: 500px) {
    .logo-landing {
        display:none
    }

    .micro-logo-landing {
        display:block;
        height:46px;
    }
}


