/* General Landing Page Styles */
.LandingPageC {
    width: 100vw;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow:hidden;
    height: calc(100vh - 92px);

}

.landing-logoC {
    position: absolute;
    top: 40px;
    left: 60px;
    width: 140px;
}

.LandingPage-leftC h1 {
    margin: 0px;
    color: #202020;
    font-size: 42px;
    font-weight: 600;
}

.interest-descriptionC {
    color: #646464;
    font-size: 16px;
    line-height: 30px;
}

.LandingPage-leftC {
    width: calc(50vw - 120px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 60px;
    min-width: 400px;
    height: calc(100vh - 120px);
}

.landing-button-containerC {
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
}

.landing-inputC {
    height: 52px;
    width: 240px;
    color: #202020;
    font-family: 'Inter', 'sans-serif';
    border: 1px solid #64646452;
    border-radius: 4px;
    font-size: 15px;
    padding-left: 16px;
}

.landing-buttonC {
    height: 56px;
    width: 180px;
    border-radius: 4px;
    border: 0px;
    cursor: pointer;
    font-size: 16px;
    color: white;
    background-color: #FF7844;
    font-family: 'Inter', 'sans-serif';
}

.landing-buttonC:hover {
    background-color: #f67829;
}

.disclaimerC {
    font-size: 15px;
    color: #202020;
}

.LandingPage-rightC {
    width: 50vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: calc(100vh - 92px);
    overflow: hidden;
}

.landing-imageC {
    width: 100%;
    height: auto;
}

/* Tablet Styling (Portrait and Landscape) */
@media screen and (max-width: 1040px) {
    .LandingPageC {
        align-items: center;
        justify-content: center;
    }

    .LandingPage-leftC {
        width: 80vw;
        margin: 40px;
        min-width: unset;
        height: auto;
        text-align: center;
    }

    .LandingPage-rightC {
        width: 80vw;
        height: auto;
        margin-bottom: 40px;
    }

    .landing-inputC {
        width: 70vw;
    }

    .landing-buttonC {
        width: 50vw;
    }
}

/* Mobile Styling */
@media screen and (max-width: 768px) {
    .LandingPageC {
        align-items: center;
    }

    .LandingPage-leftC {
        width: 90vw;
        margin: 20px;
        min-width: unset;
        height: auto;
        text-align: center;
    }

    .LandingPage-rightC {
        width: 90vw;
        margin-top: 20px;
    }

    .landing-inputC {
        width: 80vw;
    }

    .landing-buttonC {
        width: 70vw;
    }

    .LandingPage-leftC h1 {
        font-size: 32px;
    }
}

/* Small Mobile Styling */
@media screen and (max-width: 480px) {

    .LandingPage-leftC {
        width: 95vw;
        margin: 10px;
        height: auto;
        text-align: center;
    }

    .LandingPage-rightC {
        width: 95vw;
        margin-top: 10px;
    }

    .landing-inputC {
        width: 90vw;
    }

    .landing-buttonC {
        width: 80vw;
    }

    .LandingPage-leftC h1 {
        font-size: 28px;
    }
}
