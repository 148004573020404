.AboutUs {
    padding-left:15%;
    padding-right:15%;
    display:flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: #f9f9f9;
}

.AboutUs h2 {
    margin-top:40px;
    margin-bottom:8px;
    font-size:24px;
    font-weight:500;
    color:#13164B;
}

.AboutUs p {
    color:#646464;
    margin:0px;
    font-weight:300;
}

.aboutus-link {
    text-decoration: none;
    font-size:14px;
    margin-top:40px;
    margin-bottom:40px;
    display:flex;
    flex-direction: row;
    gap:8px;
    justify-content: center;
    align-items: center;
}

.aboutus-link:hover {
    gap:10px;
}

.AboutUs a p {
    color:#FF7844;
    font-weight:500

}

.about-us-container {
    display:flex;
    gap:20px;
    margin-top:20px;
    flex-wrap:wrap;
    justify-content: center;
}

.person-container {
    display:flex;
    flex-direction: column;
}

.person-image-container {
    height:calc((70vw - 40px)/3);
    width:calc((70vw - 40px)/3);
    overflow: hidden;
    display:flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    min-width:240px;
    min-height:240px;

}
.person-image {
    width:120%;
}

.person-container h6 {
    margin-top:12px;
    margin-bottom:0px;
    font-size:18px;
    color:#202020;
    font-weight:500;
}