/* General Landing Page A Styles */
.LandingPageA {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    height:calc(100vh - 92px)
}

.tagline-landing-a {
    font-size: 52px;
    font-weight: 600;
    line-height: 76px;
    width: 90%;
    text-align: center;
    color: #202020;
    margin-top: 0px;
    margin-bottom: 0px;
}

.descriptor-landing-a {
    font-size: 18px;
    line-height: 32px;
    font-weight: 300;
    width: 60%;
    text-align: center;
    color: #646464;
    margin-top: 0px;
    margin-bottom: 0px;
}

.main-image-landing-a {
    width: 700px;
    margin-bottom: 0px;
    margin-top: 50px;
    min-width: 550px;
}

.tagline_descriptor-landing-a {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 20px;
}

.landing-button-containerA {
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
}

.landing-inputA {
    height: 52px;
    width: 340px;
    color: #202020;
    font-family: 'Inter', 'sans-serif';
    border: 1px solid #64646452;
    border-radius: 4px;
    font-size: 15px;
    padding-left: 16px;
}

.landing-buttonA {
    height: 56px;
    width: 120px;
    border-radius: 4px;
    border: 0px;
    cursor: pointer;
    font-size: 16px;
    color: white;
    background-color: #FF7844;
    font-family: 'Inter', 'sans-serif';
}

.landing-buttonA:hover {
    background-color: #f67829;
}

/* Tablet Styling (Portrait and Landscape) */
@media (max-width: 1000px) {
    .main-image-landing-a {
        width: 500px;
    }

    .tagline-landing-a {
        font-size: 48px;
    }

    .descriptor-landing-a {
        width: 80%;
        margin-top: 12px;
    }
}

/* Mobile Styling */
@media (max-width: 768px) {
    .LandingPageA {
        justify-content: center;
        align-items: center;
        padding: 0px 20px;
        width: calc(100vw - 40px);
        text-align: center;
    }

    .main-image-landing-a {
        width: 90vw;
        min-width: 0px;
        margin-top: 30px;
    }

    .tagline-landing-a {
        font-size: 40px;
        line-height: 50px;
        width: 100%;
    }

    .descriptor-landing-a {
        width: 90vw;
        font-size: 16px;
        line-height: 28px;
        margin-top: 10px;
    }

    .landing-inputA {
        width: 70vw;
    }

    .landing-buttonA {
        width: 74vw;
    }
    .landing-button-containerA {
        flex-direction:column;
    }
}

/* Small Mobile Styling */
@media (max-width: 550px) {
    .LandingPageA {
        height: calc(100vh - 92px);
        justify-content: center;
    }

    .tagline-landing-a {
        font-size: 32px;
        line-height: 42px;
    }

    .descriptor-landing-a {
        font-size: 14px;
        line-height: 24px;
        width: 95%;
    }

    .landing-inputA {
        width: 80vw;
    }

    .landing-buttonA {
        width: 85vw;
    }
}
