.Terms {
    width: 100vw;
    display: flex;
    justify-content: center;
}

.terms-container {
    width: 60%;
    margin: 0 auto;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.8); /* Transparent white */
    border: 1px solid #ccc; /* Light grey border */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 20px;
}

@media (max-width: 768px) {
    /* Your CSS styles go here */
    .terms-container {
      width: 80%;
    }
  }

.terms-container h2 {
    font-size: 28px;
    color: #333;
    margin-bottom: 16px;
}

.terms-container h3 {
    font-size: 22px;
    color: #333;
    margin-top: 24px;
    margin-bottom: 8px;
}

.terms-container p {
    font-size: 16px;
    color: #555;
    line-height: 1.6;
    margin-bottom: 12px;
}

@media (max-width: 400px) {
    /* Your CSS styles go here */
    .terms-container p {
        font-size: 14px;
    }
  }

.terms-container a {
    color: #007bff;
    text-decoration: none;
}

.terms-container a:hover {
    text-decoration: underline;
}
