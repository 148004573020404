/* Main */
.Footer {
    border-top:1px solid #b9b9b9a2;
    padding: 60px 6% 60px 6%;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap:wrap;
    gap:30px;

}

.footer-main-right {
    display:flex;
    flex-direction: row;
    gap:32px;
    flex-wrap:wrap;
}

.footer-headers {
    margin:0px;
    font-size:16px;
    color:#202020;
    font-weight:500;
}

.footer-link {
    margin:0px;
    font-size:16px;
    color:#646464;
    font-weight:300;
    text-decoration: none;
}

/* Left Main */
.footer-logo {
    height:36px
}

.copyright {
    color:#646464
}

/* Middle Main */


.footer-middle {
    display:flex;
    flex-direction: column;
    width:240px;
    gap:8px;
}

/* Right Main */
.footer-right {
    display:flex;
    flex-direction: column;
    width:240px;
    gap:8px;
}

@media (max-width: 900px) {
    /* Your CSS styles go here */
    .footer-middle {
        width:180px;
    }
    .footer-right {
        width:180px;
    }
  }

  @media (max-width: 445px) {
    /* Your CSS styles go here */
    .Footer {
        justify-content: center;
        text-align:center;
    }
    .footer-main-right {
        justify-content: center;
        text-align:center
    }
  }