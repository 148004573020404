.Interest {
    height:calc(100vh - 120px);
    margin-top:120px;
    width:100vw;
    display:flex;
    flex-direction: row;
    flex-wrap:wrap-reverse;
    overflow:hidden;
}

.interest-logo {
    position:absolute;
    left:60px;
    width:140px;
    top:-80px;
}



.Interest-left h1 {
    margin:0px;
    color:#202020;
    font-size:42px;
    font-weight:600
}

.interest-description {
    color:#646464;
    font-size:16px;
    line-height:30px;
}

.Interest-left {
    width:calc(54vw - 120px);
    display:flex;
    flex-direction: column;
    justify-content: center;
    margin:60px;
    min-width:400px;
    height:calc(100vh - 120px);
}

.input-button-container {
    display:flex;
    flex-direction:row;
    gap:12px;
    align-items: center;
    flex-wrap: wrap;
}

.interest-description-mobile {
    display:none;
    color:#646464;
    font-size:14px;
    line-height:30px;
}

.interest-input {
    height:52px;
    min-width:240px;
    color:#202020;
    font-family: 'Inter', 'sans-serif';
    border: 1px solid #64646452;
    border-radius: 4px;
    font-size:15px;
    padding-left:16px;
}

.interest-button {
    height:56px;
    min-width:180px;
    border-radius:4px;
    border:0px;
    cursor:pointer;
    font-size:16px;
    color:white;
    background-color:#FF7844;
    font-family: 'Inter', 'sans-serif';
}
.disabled {
    cursor:default;
}
 
.interest-button:hover {
    background-color: #f67829;
}

.disclaimer {
    font-size:15px;
    color:#202020;
}

.disclaimer-link {
   color:#202020 
}

.disclaimer-link:hover {
    color:#f67829;
}

.Interest-right {
    width:calc(46vw - 120px);
    display:flex;
    flex-direction: column;
    justify-content: center;
    height:calc(100vh - 120px);
    margin:60px;
}

@media (max-width: 963px) {
    /* Your CSS styles go here */
    .Interest-left {
      width:calc(100vw - 120px);
      min-width:200px;
      height:auto;
      margin-top:20px;
      margin-right:60px;
      margin-left:60px;
      margin-bottom:60px;
      text-align: center;
    }
    .Interest-right {
        width:100vw;
        height:44vh;
        overflow:hidden;
        margin-top:120px;
        margin-bottom:20px;
        display:flex;
        justify-content: center;
        align-items: center;
    }
    .Interest-right img {
        transform: translateY(60px); /* Adjust the value to control vertical offset */
    }

    .Interest-left h1 {
        font-size:28px;
    }
    .interest-description {
        font-size:14px;
    }
    .input-button-container {
        display:flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
  }

@media (max-width:650px) {
    .Interest-right img {
        width:100%;
    }
    .disclaimer {
        display:none;
    }
    .interest-description-mobile {
        display:flex;
    }
    .interest-description {
        display:none;
    }
}

@media (max-width:571px) {
    .interest-button {
        width: 90%
    }
    .interest-input {
        width: calc(90% - 18px);
        min-width:0px;
    }
    .Interest-left {
        margin:20px;
        width: calc(100vw - 40px);
    }
    .Interest-right {
        margin:20px;
    }
}

.loader {
    border: 2px solid #f3f3f3; /* Light gray */
    border-top: 2px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 15px;
    height: 15px;
    animation: spin 1s linear infinite;
    display: inline-block;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}