.Feature {
    width:100%;
    display:flex;
    align-items:center;
    justify-content: flex-start;
    flex-direction: column;
}

.feature-tagline {
    font-size: 52px;
    font-weight:600;
    line-height: 76px;
    width:90%;
    text-align:center;
    color:#202020;
    margin-top:35px;
    margin-bottom:0px;
}

.feature-descriptor {
    font-size:18px;
    line-height:32px;
    font-weight:300;
    width:60%;
    text-align:center;
    color:#646464;
    margin-top: 0px;
    margin-bottom:0px;
}

.tagline_descriptor {
    display:flex;
    flex-direction: column;
    width:100%;
    display:flex;
    align-items:center;
    justify-content: flex-start;

}

.button-only-container {
    display:flex;
    flex-direction:row;
    gap:20px;
}

.no-credit-card {
    color:#646464;
    text-align: center;
    display:flex;
    flex-direction:row;
    align-items: center;
    justify-content: center;
    gap:10px;
    font-weight:300;
}

.home-getting-started {
    background-color:#FF7844;
    color:#F5F5F5;
    padding:16px;
    padding-right:28px;
    padding-left:28px;
    border-width:0px;
    border-radius:8px;
    font-family: 'Inter', sans-serif;
    font-size:20px;
    font-weight:400;
    cursor:pointer;
    text-decoration: none;
    display:flex;
    justify-content: center;
    align-items: center;
}

.home-getting-started:hover {
    background-color:#ffad7b;
    opacity: 0.6;
    transition: 0.3s;
}

.home-book-demo {
    background-color:transparent;
    color:#202020;
    padding:16px;
    padding-right:28px;
    padding-left:28px;
    border:1px solid #6464647c;
    border-radius:8px;
    font-family: 'Inter', sans-serif;
    font-size:20px;
    font-weight:400;
    cursor:pointer;
    text-decoration: none;
    display:flex;
    justify-content: center;
    align-items: center;
}

.home-book-demo:hover {
    color:#ffad7b;
    border:1px solid #ffae7b79;

}

.buttons-container {
    margin-top:18px;
}

.feature-main-image {
    margin-top:12px;
    height:55vh
}

.second-Feature {
    width:100%;
    margin-top:100px;
    display:flex;
    align-items: center;
    flex-direction: column;
}

.feature-name {
    text-align: center;
    width:60%;
    color:#202020;
    font-size:36px;
    font-weight: 500;
    margin-bottom:56px;
}

.second-home-p {
    color:#202020;
    font-size:20px;
    line-height:28px;
    font-weight:300;
    width:70%;
    text-align: center;
    margin-top:12px;
}

.third-Feature {
    width:100%;
    display:flex;
    justify-content: center;
}

.landing-block-container {
    display:flex;
    flex-direction: column;
    gap:80px;
    width:80%;
    margin-top:100px;
    margin-bottom:50px;
}

@media (max-width: 1000px) {
    .landing-block-container {
        gap:40px;
        margin-top:40px;
    }

    .second-Feature {
        margin-top: 50px;
    }
}

@media (max-width: 950px) {
    .feature-tagline {
        font-size:48px;
        line-height: 58px;
        margin-top:60px;
    }
    .feature-descriptor {
        font-size:20px;
        width:80%;
    }

    .feature-main-image {
        margin-top:30px;
        width:80%;
        height:auto;
    }
}

@media (max-width: 500px) {
    .feature-tagline {
        font-size:38px;
        line-height: 42px;
        margin-top:40px;
        width:80%;
    }
    .feature-descriptor {
        font-size:18px;
        width:70%;
    }

    .button-only-container {
       display:flex;
       flex-direction: column; 
    }

    .feature-main-image {
        margin-top:30px;
        width:80%;
    }
    .second-Feature {
        margin-top:0px;
    }
}
