.GeneralCardLink {
    width:calc(100% - 80px);
    display:flex;
    flex-direction:row;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    border-radius: 8px;
    padding:40px;
    border:1px solid #b9b9b9a2;
    margin-bottom:40px;
    min-height:44.5px;
    flex-wrap: wrap;
    gap:20px;
}

@media (max-width: 685px) {
    /* Your CSS styles go here */
    .GeneralCardLink {
      justify-content: center;
      text-align: center;
    }
  }

.GeneralCardLink h5 {
    color:#202020;
    font-size:24px;
    margin:0px;
    font-weight:400;
}

@media (max-width: 400px) {
    /* Your CSS styles go here */
    .GeneralCardLink {
      padding-left:20px;
      padding-right:20px;
      width:calc(100% - 40px);
    }

    .GeneralCardLink h5 {
        font-size:20px;
    }
  }

.GeneralCardLink a {
    background-color:#FF7844;
    color:#F5F5F5;
    padding:8px;
    padding-right:14px;
    padding-left:14px;
    border-width:0px;
    border-radius:8px;
    font-family: 'Poppins', sans-serif;
    font-size:16px;
    font-weight:400;
    cursor:pointer;
    text-decoration: none;
    display:flex;
    justify-content: center;
    align-items: center;
}

.GeneralCardLink a:hover {
    background-color:#ffad7b;

}