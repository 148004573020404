.mobile-nav-container {
    display:none
}

.web-nav-container {
    display:block;
    position:sticky;
    top:0px;
}

@media screen and (max-width: 1020px) {
    .mobile-nav-container {
        display: block;
        position:sticky;
        top:0px;
    }

    .web-nav-container {
        display: none;
    }
}