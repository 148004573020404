.Careers {
    padding-left:12%;
    padding-right:12%;
    height:calc(100vh - 93px - 40px);
}

.Careers h1 {
    margin:0px;
    color:#202020;
    font-weight:600;
    margin-top:40px;
    margin-bottom:10px;
}

.Careers h6 {
    color:#646464;
    font-size:18px;
    font-weight:400;
    margin:0px;
}