.Home {
    width:100%;
    display:flex;
    align-items:center;
    justify-content: flex-start;
    flex-direction: column;
}

.tagline {
    font-size: 52px;
    font-weight:600;
    line-height: 76px;
    width:90%;
    text-align:center;
    color:#202020;
    margin-top:0px;
    margin-bottom:0px;
}

.descriptor {
    font-size:18px;
    line-height:32px;
    font-weight:300;
    width:60%;
    text-align:center;
    color:#646464;
    margin-top: 0px;
    margin-bottom:0px;
}

.buttons-container {
    margin-top:22px;
    margin-bottom: 0px;
}

.main-image {
    width:620px;
    margin-bottom:0px;
    margin-top:0px;
    min-width:550px;
}

.no-credit-card {
    color:#646464;
    text-align: center;
    display:flex;
    flex-direction:row;
    align-items: center;
    justify-content: center;
    gap:10px;
    font-weight:300;
    margin-top:12px;
    margin-bottom:0px;
}

.tagline_descriptor {
    display:flex;
    flex-direction: column;
    width:100%;
    display:flex;
    align-items:center;
    justify-content: flex-start;

}

.button-only-container {
    display:flex;
    flex-direction:row;
    gap:20px;
}

.home-getting-started {
    background-color:#FF7844;
    color:#F5F5F5;
    padding:16px;
    padding-right:28px;
    padding-left:28px;
    border-width:0px;
    border-radius:8px;
    font-family: 'Inter', sans-serif;
    font-size:20px;
    font-weight:400;
    cursor:pointer;
    text-decoration: none;
    display:flex;
    justify-content: center;
    align-items: center;
}

.home-getting-started:hover {
    background-color:#ffad7b;
    opacity: 0.6;
    transition: 0.3s;
}

.home-book-demo {
    background-color:transparent;
    color:#202020;
    padding:16px;
    padding-right:28px;
    padding-left:28px;
    border:1px solid #6464647c;
    border-radius:8px;
    font-family: 'Inter', sans-serif;
    font-size:20px;
    font-weight:400;
    cursor:pointer;
    text-decoration: none;
    display:flex;
    justify-content: center;
    align-items: center;
}

.home-book-demo:hover {
    color:#ffad7b;
    border:1px solid #ffae7b79;

}

.second-Home {
    width:100%;
    margin-top:100px;
    display:flex;
    align-items: center;
    flex-direction: column;
}

.second-Home-h2 {
    text-align: center;
    width:60%;
    color:#202020;
    font-size:36px;
    font-weight: 500;
    margin-bottom:12px;
}

.second-home-p {
    color:#646464;
    font-size:18px;
    line-height:28px;
    font-weight:300;
    width:70%;
    text-align: center;
    margin-top:0px;
}

.third-Home {
    width:100%;
    display:flex;
    justify-content: center;
}

.card-container {
    width:84.2%;
}

.feature-cards-container {
    width:84%;
}

.active-feature-card {
    width: calc(84%);
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-top: 60px;
    height: 700px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 60px;
    gap:40px;
    overflow:hidden;
}

.active-feature-card-heading-section {
    display:flex;
    flex-direction:column;
    align-items: flex-start;
    padding-left:40px;
    width:600px;
}

.active-card-header {
    font-size:40px;
    color:#202020;
    font-weight:400;
    margin:0px;
    margin-bottom:16px;
}

.active-feature-description {
    font-size:18px;
    color:#646464;
    margin:0px;
    font-weight:300;
    line-height:26px;

}

.active-feature-image {
    width:130%
}

.active-feature-image-container {
    width:60%;
    
}

@media (max-width:1000px) {
    .main-image {
        width: 500px;
    }
    .tagline {
        font-size:48px;
    }
    .descriptor {
        width:80%;
        margin-top:12px;
    }
    .buttons-container {
        margin-top:24px;
    }
    .second-Home {
        margin-top: 50px;
        margin-bottom:50px;
    }
}

@media (max-width:650px) {
    .main-image {
        width:90vw;
        min-width:0px;
    }
    .tagline {
        font-size:6.6vw;
        line-height: 42px;
    }
    .descriptor {
        font-size:16px;
    }
}

@media (max-width: 640px) {
    .button-only-container {
        display:flex;
        flex-direction: column; 
     } 
     .buttons-container {
        margin-top:12px;
     }
}

@media (max-width:550px) {
    .Home {
        height:calc(100vh - 92px);
        justify-content: center;
    }
    .descriptor {
        font-size:16px;
        line-height:32px;
    }
    .tagline {
        font-size:32px;
    }
}

/* Check out */
.active-feature-card-heading-section, .active-feature-image {
    opacity:0;
    transition: opacity 1s ease;
}

.active-feature-card-heading-section.activeness, .active-feature-image.activeness {
    opacity:1;
}

@media (max-width: 900px) {
    /* Your CSS styles go here */
    .active-feature-card {
        flex-wrap:wrap;
        padding:40px;
        width:calc(84% - 80px);
        justify-content: center;
        align-items: center;
        gap:0px;
        height:auto;
    }
    .second-home-p {
        font-size: 16px;
        line-height: 32px;
    }
    .active-feature-description {
        font-size: 16px;
        line-height: 24px;
    }
    .active-card-header {
        font-size:30px;
    }
    .active-feature-card-heading-section {
        padding:0px;
    }
    .active-feature-image-container {
        min-width:100%
    }
    .active-feature-image {
        width:100%;
    }
}

@media (max-width: 600px) {
    /* Your CSS styles go here */
    .active-feature-card {
        padding:20px;
        width:calc(84% - 40px);
    }
    .active-card-header {
        font-size:24px;
    }
}
