.FeatureCard {
    cursor: pointer;
    padding: 16px;
    border: 1px solid #ddd;
    border-radius: 12px;
    transition: box-shadow 0.6s ease, background-color 0.6s ease;
    min-height: 176px;
    width:calc((80vw - 64px)/5);
}

.FeatureCard:hover {
    border: 1px solid #ff7944;
}

.FeatureCard.feature-active {
    background-color: #ff7944ea;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Active shadow */
    border: 1px solid #ddd;
}

.FeatureCard h5 {
    color: #202020;
    font-size:18px;
    margin-top:18px;
    margin-bottom:12px;
    font-weight:500;
    transition: color 0.3s ease;

}

.FeatureCard.feature-active h5 {
    color: white;
}

.FeatureCard p {
    color: #202020;
    font-size:14px;
    color:#646464;
    text-align: left;
    width:100%;
    line-height:20px;
    transition: color 0.3s ease;

}

.FeatureCard.feature-active p {
    color: white;
}

.icon-container {
    background-color: aliceblue;
    width:36px;
    height:36px;
    display:flex;
    justify-content: center;
    align-items: center;
    border-radius:4px;
    color: #ff7944ea;

}

.FeatureCard.feature-active div {
    background-color: white;

}

.feature-cards-container {
    display: flex;
    gap: 16px;
}

@media (max-width: 900px) {
    /* Your CSS styles go here */
    .feature-cards-container {
      flex-direction:column;
    }

    .FeatureCard {
        width:calc(100% - 32px);
        min-height:100px;
    }
}