/* General Landing Page B Styles */
.LandingPageB {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    overflow: hidden;
    height: calc(100vh - 92px);
}

.tagline-landing-b {
    font-size: 60px;
    font-weight: 500;
    line-height: 76px;
    width: 56%;
    text-align: center;
    color: #202020;
    margin-top: 40px;
    margin-bottom: 0px;
}

.descriptor-landing-b {
    font-size: 18px;
    line-height: 32px;
    font-weight: 300;
    width: 62%;
    text-align: center;
    color: #646464;
    margin-top: 20px;
    margin-bottom: 0px;
}

.main-image-landing-b {
    width: 920px;
    margin-bottom: 0px;
    margin-top: 0px;
    min-width: 550px;
    margin-right: 60px;
}

.tagline_descriptor-landing-b {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
}

/* Responsive button and input container */
.landing-button-containerB {
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.landing-inputB {
    height: 44px;
    width: 340px;
    color: #202020;
    font-family: 'Inter', 'sans-serif';
    border: 1px solid #64646452;
    border-radius: 4px;
    font-size: 15px;
    padding-left: 16px;
}

.landing-buttonB {
    height: 48px;
    width: 120px;
    border-radius: 4px;
    border: 0px;
    cursor: pointer;
    font-size: 16px;
    color: white;
    background-color: #FF7844;
    font-family: 'Inter', 'sans-serif';
}

.landing-buttonB:hover {
    background-color: #f67829;
}

/* Tablet Styling (Portrait and Landscape) */
@media (max-width: 1000px) {
    .main-image-landing-b {
        width: 700px;
    }

    .tagline-landing-b {
        font-size: 48px;
    }

    .descriptor-landing-b {
        width: 80%;
        margin-top: 12px;
    }
}

/* Mobile Styling */
@media (max-width: 768px) {
    .LandingPageB {
        justify-content: center;
        align-items: center;
        padding: 0px 20px;
        text-align: center;
        width:calc(100vw - 40px)
    }

    .main-image-landing-b {
        width: 500px;
        min-width: 0px;
        margin-top: 20px;
        margin-right: 0;
    }

    .tagline-landing-b {
        font-size: 40px;
        line-height: 50px;
        width: calc(100% - 40px);
    }

    .descriptor-landing-b {
        width: 90%;
        font-size: 16px;
        line-height: 28px;
        margin-top: 10px;
    }

    .landing-button-containerB {
        flex-direction:column;
    }

    .landing-inputB {
        width: 70vw;
    }

    .landing-buttonB {
        width: 73vw;
    }
}

/* Small Mobile Styling */
@media (max-width: 550px) {
    .LandingPageB {
        justify-content: flex-start;
    }

    .tagline-landing-b {
        font-size: 42px;
        line-height: 42px;
    }

    .descriptor-landing-b {
        font-size: 16px;
        line-height: 24px;
        width: 95%;
    }

    .landing-inputB {
        width: 80vw;
    }

    .landing-buttonB {
        width: 84vw;
    }

    .main-image-landing-b {
        width: calc(90vw - 40px);
    }
}
