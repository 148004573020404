.LandingBlock {
    display:flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
    width:100%;
    gap:50px;
}

.LandingBlock-content {
    flex: 1;
    padding: 1rem;
}


.LandingBlock-image {
    flex:1;
    width:20%;
}

.LandingBlock.row {
    flex-direction: row;
}

.LandingBlock.row-reverse {
    flex-direction: row-reverse;
}

.LandingBlock-content h4 {
    color:#202020;
    font-size:32px;
    font-weight:500;
    margin-bottom:20px;
}

.LandingBlock-content p {
    color:#646464;
    font-size:18px;
    font-weight:300;
}

@media (max-width: 1000px) {
    /* Your CSS styles go here */
    .LandingBlock.row {
        flex-direction: column;
    }
    
    .LandingBlock.row-reverse {
        flex-direction: column;
    }

    .LandingBlock-image {
        width:60%;
    }

    .LandingBlock {
        gap:20px;
    }

    .LandingBlock-content {
        text-align: center;
    }
  }

  @media (max-width: 600px) {
    .LandingBlock-image {
        width:90%;
    }
    .LandingBlock-content h4 {
        font-size:26px;
    }
    .LandingBlock-content p {
        font-size:16px;
    }
  }